
import React from "react";


const images = [
    { src: "https://shecluded-medias.s3.us-east-2.amazonaws.com/6G9O7936.JPG", alt: "woman smiling" },
    { src: "https://shecluded-medias.s3.us-east-2.amazonaws.com/6G9O8300.JPG", alt: "woman smiling" },
    { src: "https://shecluded-medias.s3.us-east-2.amazonaws.com/6G9O8254.JPG", alt: "woman smiling" },
    { src: "https://shecluded-medias.s3.us-east-2.amazonaws.com/6G9O8294.JPG", alt: "woman smiling" },
    { src: "https://shecluded-medias.s3.us-east-2.amazonaws.com/6G9O7945.JPG", alt: "woman smiling" },
]

export default function HeroImageCarousel() {

    return (
        <div className="hero-images pt-6 pb-12">
            <div className="inline-flex w-full flex-nowrap overflow-hidden">
                <ul className="flex animate-infinite-scroll items-center justify-center md:justify-start [&_img]:max-w-none [&_li]:mx-2">
                    {images.map((image, index) => (
                        <li key={index}>
                            <img
                                src={image.src}
                                alt={image.alt}
                                className="w-[300px] h-[240px] object-cover rounded-lg"
                            />
                        </li>
                    ))}
                </ul>
                <ul className="flex animate-infinite-scroll items-center justify-center md:justify-start [&_img]:max-w-none [&_li]:mx-2">
                    {images.map((image, index) => (
                        <li key={index}>
                            <img 
                                src={image.src}
                                alt={image.alt}
                                className="w-[300px] h-[240px] object-cover rounded-lg"
                            />
                        </li>
                    ))}
                </ul>

            </div>
        </div>

    )
}