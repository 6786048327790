import React from "react";
import { Link } from "react-router-dom";


export default function Actionbutton({to, text, background='bg-light-pink', outline='primary', fontsize='text-sm/6', horzpadding='px-8'}) {
    return(
        <Link to={to} className={`inline-flex items-center rounded-full ${background} py-3 ${horzpadding} ${fontsize} font-semibold text-primary ${outline && `outline ${outline}`} outline-1`}>
             {text}
       </Link>

    )
}