import { ArrowRightStartOnRectangleIcon, ChatBubbleBottomCenterIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline"
import { Link } from 'react-router-dom'


export default function ContactUs() {
    return (
        <section className="contact-us mt-6 lg:mt-14 px-6 lg:px-14">
              <h1 className="lg:pt-6 text-2xl lg:text-3xl text-text-color  font-semibold">Reach out to us
              </h1>
            <div className="flex flex-col lg:flex-row  lg:gap-x-4 mt-6 gap-y-6 lg:gap-y-0">
                <Link to="/loans">
                <div className="group contact-card outline outline-1 outline-outline-bg py-10 pl-6 pr-8 rounded-lg hover:bg-light-pink-8 hover:outline-none">
                 
                <div className="icon-circle w-[37px] h-[37px] rounded-full bg-light-pink-5 flex align-center justify-center group-hover:bg-light-pink-8 ">
                       
                        <ArrowRightStartOnRectangleIcon className="h-5 w-5 text-primary mt-2"></ArrowRightStartOnRectangleIcon>
                       </div>
                       <h1 className="mt-4 text-lg text-text-color font-semibold">Log In
                      </h1>
                      <p className="text-base text-dark-grey-1 pt-2">Log in to your personal account to manage your loans</p>
                </div>
                </Link>
                <Link to="https://api.whatsapp.com/send?phone=2347048000892">
                <div className="group contact-card outline outline-1 outline-outline-bg py-10 pl-6 pr-8 rounded-lg hover:bg-light-pink-8 hover:outline-none">
                <div className="icon-circle w-[37px] h-[37px] rounded-full bg-light-pink-5 flex align-center justify-center group-hover:bg-light-pink-8">
                        <ChatBubbleBottomCenterIcon className="h-5 w-5 text-secondary mt-2"></ChatBubbleBottomCenterIcon>
                       </div>
                       <h1 className="mt-4 text-lg text-text-color font-semibold">Chat
                      </h1>
                      <p className="text-base text-dark-grey-1 pt-2">Chat with our customer care, estimated response time is 1hr</p>
                </div>
                </Link>
                <Link to="mailto:hello@shecluded.com">
                <div className="group contact-card outline outline-1 outline-outline-bg py-10 pl-6 pr-10 rounded-lg hover:bg-light-pink-8 hover:outline-none">
                <div className="icon-circle w-[37px] h-[37px] rounded-full bg-light-green-5 flex align-center justify-center group-hover:bg-light-pink-8 ">
                        <EnvelopeIcon className="h-5 w-5 text-green-1 mt-2"></EnvelopeIcon>
                       </div>
                       <h1 className="mt-4 text-lg text-text-color font-semibold">Email address
                      </h1>
                      <p className="text-base text-dark-grey-1 pt-2">Send us an email, response time is 2hrs. hello@shecluded.com</p>
                </div>
                </Link>
                <Link to="tel:+2347048000892">
                <div className="group contact-card outline outline-1 outline-outline-bg py-10 pl-6 pr-10 rounded-lg hover:bg-light-pink-8 hover:outline-none">
                <div className="icon-circle w-[37px] h-[37px] rounded-full bg-light-yellow flex align-center justify-center group-hover:bg-light-pink-8">
                        <PhoneIcon className="h-5 w-5 text-yellow mt-2"></PhoneIcon>
                       </div>
                       <h1 className="mt-4 text-lg text-text-color font-semibold">Phone
                      </h1>
                      <p className="text-base text-dark-grey-1 pt-2">Estimated response time is less than 1hr. +234-704-800-0892</p>
                </div>
                </Link>
            </div>
            
        </section>
    )
}