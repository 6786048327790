import React from "react";

import Header from "./header";
import VideoTestimonal from "./videoTestimonal";
import BlogNews from "components/layout/blogNews";
import Faq from "components/layout/homeFaq";
import Footer from "components/layout/footer";
import JoinCommunityBanner from "components/layout/communityBanner";
import ServicesCardStack from "./servicescardStack";
import ContactUs from "components/layout/contactTab";


export default function HomePage(){
    return (
        <>
          <Header />
          <ServicesCardStack />
          <VideoTestimonal />
          <BlogNews />
          <Faq />
          <JoinCommunityBanner />
          <ContactUs />
          <Footer />
        </>
    )
}

