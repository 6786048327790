
export default function VideoTestimonal(){
    return (
        <section className="video_test my-4">
            <div className="bg-black rounded-2xl py-10 text-white flex flex-col items-center justify-center px-6 lg:px-0">
            <h1 className="max-w-2xl lg:max-w-3xl text-3xl lg:text-5xl pt-4 lg:pt-6 text-center tracking-normal font-medium ">We are changing the future for
             women owned businesses</h1>
            <p className="max-w-lg text-center pt-2 pb-4 lg:pt-6 lg:pb-6 text-light-grey-1 text-base md:text-lg font-medium "> Meet Rasheedat Balogun, a fish farmer who has thrived with Shecluded’s support.</p>
          
              <div className="video-embed-container">
              <video src={"https://shecluded-medias.s3.us-east-2.amazonaws.com/testimonial_video.mp4"} width="750" height="500" controls></video>
              </div>
            </div>
      
        </section>
    )
}

