import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'


const homeFaqs = [
   { question: "What is Shecluded?", answer: "Shecluded is a women-first financial solutions company dedicated to empowering women entrepreneurs. We provide funding options, asset-building tools, and financial education resources specifically designed to help women overcome financial challenges and achieve economic independence."},
   { question: "Is there a community I can join?", answer: "Yes! Shecluded has an actively engaged community of over 22,000 women across Nigeria and Malawi. By joining, you can connect with like-minded entrepreneurs, access valuable resources, and participate in events that foster collaboration and growth."},
   { question: "Who can apply for funding through Shecluded?", answer: "Women entrepreneurs from diverse social and economic backgrounds can apply for funding through Shecluded. Our programs are designed to support both startups and established businesses looking for growth opportunities."},
   { question: "What types of financial services does Shecluded offer?", answer: "Shecluded offers a range of financial services, including growth loans tailored to women's needs, financial literacy resources, insurance packages for better healthcare access, and business support services like branding and marketing advisory."},
   { question: "What documents do I need when applying for loan?", answer: "When applying for a loan through Shecluded, you typically need to provide identification documents (such as a national ID or passport), proof of business registration, a business plan outlining your goals and financial projections, and any additional documentation that may support your application."}
]



export default function Faq() {
    return (
        <section className='pt-6 mb-0 pb-0'>
            <h1 className="lg-max-w-4xl md-max-w-xl text-3xl lg:text-5xl  text-center text-text-color  tracking-normal font-semibold">FAQ </h1>
            <div className="w-full pt-6 lg:pt-10 px-6 lg:px-14">
                <div className="w-full">
                    {homeFaqs.map((faq, index) => (
                    <Disclosure as="div" className="rounded-2xl lg:rounded-full bg-light-grey-5 py-6 px-6 lg:px-14 mb-6">
                        <DisclosureButton className="group flex w-full items-center justify-between">
                            <span className="text-base lg:text-lg font-semibold text-text-color text-left group-data-[hover]:text-primary">
                                   {faq.question}
                            </span>
                            <ChevronRightIcon className="size-5 lg:size-7 fill-black group-data-[hover]:fill-primary group-data-[open]:rotate-180" />
                        </DisclosureButton>
                        <DisclosurePanel
                            transition
                            className="mt-3 text-sm lg:text-base text-dark-grey-1 lg:pr-20 transition duration-200 ease-out">
                           {faq.answer}
                        </DisclosurePanel>
                    </Disclosure>
                   ))}
                  
                  
                </div>
            </div>
            <hr className='my-10 border-1 border-light-grey-5'></hr>
        </section>
        
    )
}