import Header from "./header";
import EmptyMain from "./main";


export default function Empty() {
    return (
     <>
      <Header />
      <EmptyMain />
      </>
    );

}